.noHeight {
  height: auto
}

.alignBottom {
  align-content: end;
}

.toggleBtn {
  justify-content: flex-start !important;
}
